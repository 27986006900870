import { Chart } from "@domoinc/domo-phoenix";
import React, { useState, useEffect, useRef } from "react";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H1 } from "src/components/Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function Phoenix({ pixelWidth = { sm: 800, md: 1000, base: 1000, lg: 1250, xl: 1250 } }) {
  const dashboardRef = useRef(null);
  const chartsLoadedCount = useRef(0);
  const totalChartsCount = useRef(0);
  const isChartsLoaded = useRef(false);

  const exportToPDF = async () => {
    if (!isChartsLoaded.current || !dashboardRef.current) return;

    const canvas = await html2canvas(dashboardRef.current, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("dashboard.pdf");
  };

  const DomoChart = React.memo(({ chartData, onLoad }) => {
    const chartDiv = useRef(null);

    useEffect(() => {
      const { type, rows, columns, styles, colors, properties } = chartData;
      const PADDING = 16;

      const ratio = (pixelWidth.base / 60) * 0.95;
      const standardWidth = styles.pageLayoutV4.standard.width * ratio;
      const standardHeight = (styles.pageLayoutV4.standard.height * ratio) / 1.67;

      const adjustedWidth = standardWidth - PADDING * 2;
      const adjustedHeight = standardHeight - PADDING * 2;

      const options = {
        width: adjustedWidth,
        height: adjustedHeight,
        properties,
        colors,
      };
      const data = { rows, columns };

      const chart = new Chart(type, data, options);
      chartDiv.current.appendChild(chart.canvas);
      chart.render();

      if (onLoad) onLoad();

      return () => {
        chartDiv.current.removeChild(chart.canvas);
      };
    }, [chartData, onLoad]);

    return (
      <div
        ref={chartDiv}
        className="chart-container"></div>
    );
  });

  const Dashboard = () => {
    const [formatData, setFormatData] = useState(null);

    async function handleDomoCall() {
      let pageId = 1824589741;
      const { data } = await apiRequest("get", `/phoenix-library/page/${pageId}`);
      return { settings: data.settings, cards: data.cards };
    }

    useEffect(() => {
      const fetchPages = async () => {
        const formatData = await handleDomoCall();
        setFormatData(formatData);
        totalChartsCount.current = formatData.cards.length;
      };

      fetchPages();
    }, []);

    const handleChartLoad = () => {
      chartsLoadedCount.current += 1;

      if (chartsLoadedCount.current === totalChartsCount.current) {
        isChartsLoaded.current = true; // Only updating ref, not state
      }
    };

    return (
      <div
        ref={dashboardRef}
        className="flex h-auto w-full flex-wrap justify-center gap-y-3">
        {formatData ? (
          formatData.cards.map((card) => {
            const { id, type, data, styles } = card;
            const { columns, rows } = data;

            return (
              <div key={id}>
                <DomoChart
                  chartData={{ type, rows, columns, styles }}
                  onLoad={handleChartLoad}
                />
              </div>
            );
          })
        ) : (
          <div className="flex w-[1000px] justify-center">
            <Preloader />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="h-full w-full">
      <H1>
        <div>
          <div className="flex items-center justify-between">
            <div className="flex">
              Web Component
              <p className="pl-2 font-light">
                <span className="rounded bg-green-100 px-2 py-1 text-green-800">Native</span> React Code Builder
              </p>
            </div>
          </div>
          <div className="text-base font-normal text-gray-400">Demonstration only example.</div>
        </div>
      </H1>
      <div className="flex h-full w-full flex-col items-end">
        <button
          onClick={exportToPDF}
          className={classNames("mb-10 w-[200px] rounded bg-blue-500 px-4 py-2 text-white transition-all hover:bg-blue-600")}>
          Export to PDF
        </button>
        <div className={classNames(`flex h-full w-[${pixelWidth.base}px] justify-center overflow-y-scroll`)}>
          <Dashboard />
        </div>
      </div>
    </div>
  );
}
